<template>
  <div
    class="components_task_fun_bar_button_group_page d-flex align-center"
  >
    <div
      v-if="draft_status && taskConfig.showDraft"
      class="button draft"
      @click="seveDraft"
    >保存草稿</div>
    <div
      v-if="send_status && taskConfig.showPublish"
      class="button btn_dark send"
      :class="{
        unable: task.task.taskType === 2 && task.task.checkstatus !== 3
      }"
      @click="sendTask"
    >

      发布任务
    </div>
    <div
      v-if="use_status && taskConfig.showUse"
      class="button btn_dark send"
      @click="doUse"
    >我要使用</div>
    <div
      v-if="taskConfig.showAddToCourse"
      class="button btn_dark send"
      @click="addToCourse"
    >
      添加任务
    </div>


  </div>
</template>
<script>
import store from '@/store'
import { mapState } from 'vuex'
import tools from '@/api/tools'
export default {
  props: {
    view: {
      type: Boolean,
      default: false,
    },
    system: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    recycle: {
      type: Boolean,
      default: false,
    },
  },
  computed:{
    ...mapState({
      task: state => state.task,
      taskConfig: state => state.tasks.taskConfig,
    }),
    draft_status(){
      const {recycle} = this;
      const {task_config} = this.$store.state;
      const {create, draft, call, task_type, auditMode} = task_config;
      if (auditMode) {
        return false;
      }
      if(recycle){
        return false;
      }
      if(create || draft || call){
        if(task_type == 2 && !draft){
          return false;
        }
        return true;
      }
      return false;
    },
    send_status(){
      const {view, editable} = this;

      if(view){
        return false;
      }
      return editable;
    },
    use_status(){
      const {view, system} = this;
      if(system){
        return false;
      }
      if(view){
        return true;
      }
      return false;
    }

  },
  methods: {
    async sendTask () {
      const {
        task,
        teach_files,
        task_config
      } = store.state
      const {
        checkstatus,
        taskType,
      } = task.task
      if (task_config.task_type !== 3 && !task_config.call) {
        if (teach_files.fileList && teach_files.fileList.length ) {
          this.$notice({
            desc: '需要审核通过才可使用',
            type: 'error'
          })
          return
        }
        if (teach_files.linkList && teach_files.linkList.length) {
          this.$notice({
            desc: '需要审核通过才可使用',
            type: 'error'
          })
          return
        }
        if (teach_files.videoList && teach_files.videoList.length) {
          this.$notice({
            desc: '需要审核通过才可使用',
            type: 'error'
          })
          return
        }
      }

      if (taskType === 2 && task_config.task_type !== 3 && !task_config.call) {
        if (checkstatus === 2) {
          this.$notice({
            desc: '正在审核中,无法发布',
            type: 'error'
          })
          return
        }
        if (checkstatus !== 3) {
          this.$notice({
            desc: '需要审核通过才可使用',
            type: 'error'
          })
          return
        }
      }
      this.$emit('handleSend')
    },
    seveDraft(){
      const { task_config } = this.$store.state;
      const { draft } = task_config;
      const { task } = store.state
      const {
        checkstatus,
        taskType,
      } = task.task
      if (taskType === 2) {
        if (checkstatus === 2) {
          this.$notice({
            desc: '正在审核中,无法保存草稿',
            type: 'error'
          })
          return
        }
      }
      if(draft && task.task.id){ // 草稿 && 任务id存在
        this.$api.moduleTask.editDraft();
        return ;
      }
      this.$api.moduleTask.createDraft();
    },
    doUse(){
      const {task_config} = this.$store.state;
      const {task_id} = task_config;
      const { task } = store.state
      const {
        checkstatus,
        taskType,
      } = task.task
      if (taskType === 2 && checkstatus !== 3) {
        this.$notice({
          desc: '请先提交「教学区」审核',
          type: 'error'
        })
        return
      }
      this.$emit('handleUse', task_id ?? this.$store.state.task_original.task.id);
    },
    async addToCourse () {
      const { taskConfig } = this
      // 检查是否满足条件
      const canAudit = this.chenkAudit()
      if (!canAudit) {
        return
      }
      const { draft } = this.$store.state.task_config
      const id = this.$store.state.task.task.id
      const { task } = store.state
      const { taskType } = task.task

      const res = await this.$api.moduleTask.createDraft({ config:{create_teach: true}})
      if (res.message === 'success') {
        this.task.task = res.contents.task
        // this.task.task.id = res.contents.task.id
        // this.task.task.draft = res.contents.task.draft
        this.$store.state.task_config.draft = true
        this.$notice({
          desc: '保存成功',
          type: 'success'
        })
        if (taskConfig.addToCourseCallback) {
          const userInfo = tools.getCookie('user')
          const assignTask = Object.assign(
            {},
            task.task,
            res.contents.task,
            {
              updateusername: userInfo?.userName || '',
              updateuserthumbnail: userInfo?.thumbnail || '',
            },
          )
          // taskConfig.addToCourseCallback(res.contents.task)
          taskConfig.addToCourseCallback(assignTask)
        }
        // 关闭任务
        this.$api.moduleTask.closeTask()
      }
      // }
    },
    /**
     * 检查是否可以审核
     */
    chenkAudit () {
      const { state } = store
      const { task_config, task, teach_files } = state
      const { teachType } = task_config
      const {
        fileList,
        linkList,
        videoList
      } = teach_files
      if (!teachType) {
        this.$notice({
          desc: '请选择教学类型',
          type: 'error'
        })
        return false
      }
      if (0 && (!fileList || fileList.length ===0) && (!linkList || linkList.length ===0) && (!videoList || videoList.length ===0)) {
        this.$notice({
          desc: '请上传教学内容',
          type: 'error'
        })
        return false
      }

      return true
    },
  }
}
</script>
<style lang="scss" scoped>
.components_task_fun_bar_button_group_page{
  padding: 0 5px;
  .button{
    font-size: 12px;
    color: #FFFFFF;
    border-radius: 4px;
    line-height: 30px;
    padding: 0 10px;
    margin: 0 5px;
    &.draft{
      background-color: #399786;
    }
    &.unable {
      background-color: #999;
    }
  }
}
</style>
